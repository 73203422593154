.admin-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
    width: 100%;
}

.admin-shifting-container {
    display: flex;
    padding: 0 15px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BLOG POST MENU
//
////////////////////////////////////////////////////////////////////////////////////*/

.admin-menu-container {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
}

.admin-menu-container p {
    font-size: 18px;
    font-weight: 700;
    margin-bottom: 10px;
}

.admin-menu-top-row {
    display: flex;
    width: 100%;
    font-size: 25px;
    font-weight: 800;
    padding-top: 20px;
    padding-bottom: 50px;
    justify-content: space-around;
}

.admin-menu-logout {
    padding: 5px 15px;
    font-size: 15px;
    border: 1px solid #000;
    border-radius: 4px;
    cursor: pointer;
}

.admin-blog-list {
    display: flex;
    flex-direction: column;
    margin: auto;
    max-height: 500px;
    width: 50%;
    overflow-y: auto;
    border-top: 1px solid #ddd;
    margin-bottom: 30px;
}

.admin-add-button {
    border: none;
    border-radius: 3px;
    color: #fff;
    background: #2a4eaa;
    height: 40px;
    font-size: 15px;
    padding: 0px 20px;
    cursor: pointer;

}

/*////////////////////////////////////////////////////////////////////////////////////
//
// LOG IN
//
////////////////////////////////////////////////////////////////////////////////////*/

.admin-login-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.admin-login-form h2 {
    margin: 30px 0;
    font-size: 32px;
}

.admin-login-form form {
    display: flex;
    flex-direction: column;
}

.admin-login-form input {
    margin-bottom: 20px;
    padding: 3px;
    width: 300px;
}

.admin-login-form button {
    border: none;
    border-radius: 3px;
    color: #fff;
    background: #2a4eaa;
    height: 35px;
    cursor: pointer;
}


/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Misc */
    .admin-shifting-container {
        width: 100%;
    }
}

@media (min-width: 768px) {
    /* Misc */
    .admin-shifting-container {
        width: 750px;
    }
}

@media (min-width: 992px) {   
    /* Misc */
    .admin-shifting-container {
        width: 970px;
    }
}

@media (min-width: 1200px) {
    /* Misc */
    .admin-shifting-container {
        width: 1230px;
    }
}