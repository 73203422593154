.services-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    align-items: center;
    padding-top: 100px;
}

.services-shifting-container {
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
    align-items: center;
    flex-direction: column;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BANNER
//
////////////////////////////////////////////////////////////////////////////////////*/

.services-banner-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    text-align: center;
    align-items: center;
}

.services-banner {
    max-height: 45vh;
}

.services-banner-text {
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 15px;
}

.services-banner-half-span {
    width: 50%;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// COLUMN
//
////////////////////////////////////////////////////////////////////////////////////*/

.services-column-title {
    margin-top: 50px;
    font-size: 2rem
}

.services-column-wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.services-column-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(250px, 1fr));
    grid-gap: 20px;
}

.services-column {
    position: relative;
    margin-top: 50px;
    padding: 25px;
    transition: all 0.3s ease-in-out;
}

.services-column h3 {
    margin-bottom: 30px;
    padding: 5px 0;
}

.services-column:hover {
    background: #0f1d3e;
    color: white;
    transform: scale(1.1);
}

.services-icon {
    max-width: 100px;
    margin-bottom: 30px;
}

.services-list {
    margin-left: 40px;
    line-height: 1.5;
}

.services-list li {
    list-style: none;
    margin-bottom: 20px;
}

.services-list li::before {
    content: "\00BB";
    margin-right: 10px;
    margin-left: -17px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// PARAGRAPH
//
////////////////////////////////////////////////////////////////////////////////////*/

.services-para {
    display: flex;
    text-align: center;
    flex-direction: column;
}

.services-para h2 {
    font-size: 2rem;
    margin-bottom: 50px;
}

.services-para p {
    margin-bottom: 50px;
    line-height: 2;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// MISCELLANEOUS
//
////////////////////////////////////////////////////////////////////////////////////*/


.inline-link {
    display: inline;
}

.services-spacer {
    margin: 75px 0;
    border: none;
    height: 1px;
    background-color: #ddd;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Banner */
    .services-banner {
        transform: translate(-700px);
    }
    .services-banner-text h1 {
        font-size: 2.3rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .services-banner-text p {
        font-size: 0.9rem;
    }
    .services-banner-text {
        max-width: 100%;
        text-align: center;
    }
    .services-banner-half-span {
        width: 100%;
    }

    /* Misc */
    .services-shifting-container {
        max-width: 100%;
    }
    .services-spacer {
        min-width: 90%;
    }
}

@media (min-width: 768px) {
    /* Banner */
    .services-banner {
        transform: translate(-400px);
    }
    .services-banner-text h1 {
        font-size: 2.5rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .services-banner-text p {
        font-size: 1rem;
    }
    .services-banner-text {
        max-width: 750px;
        text-align: center;
    }
    .services-banner-half-span {
        width: 100%;
    }

    /* Misc */
    .services-shifting-container {
        max-width: 750px;
    }
    .services-spacer {
        min-width: 750px;
    }
}

@media (min-width: 992px) {
    /* Banner */
    .services-banner {
        transform: translate(0);
    }
    .services-banner-text h1 {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }
    .services-banner-text p {
        font-size: 1rem;
    }
    .services-banner-text {
        max-width: 970px;
        text-align: left;
    }
    .services-banner-half-span {
        width: 75%;
    }

    /* Misc */
    .services-shifting-container {
        max-width: 970px;
    }
    .services-spacer {
        min-width: 970px;
    }
}

@media (min-width: 1200px) {
    /* Banner */
    .services-banner-text h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .services-banner-text p {
        font-size: 1.1rem;
    }
    .services-banner-text {
        max-width: 1170px;
        text-align: left;
    }

    /* Misc */
    .services-shifting-container {
        max-width: 1170px;
    }
    .services-spacer {
        min-width: 1170px;
    }
}