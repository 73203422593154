.legal-container-main {
    padding: 0;
    margin: 0;
    padding-top: 100px;
    background: #fff;
    display: flex;
    justify-content: center;

}

.legal-shifting-container {
    justify-content: center;
    padding: 0 15px;
}

.legal-section {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.legal-title {
    text-align: center;
    margin: 50px 0;
}

.legal-title h1 {
    font-size: 2.5rem;
    margin-bottom: 15px;
}

.legal-section h2 {
    margin-bottom: 20px;
}

.legal-section h3 {
    margin-bottom: 5px;
}

.legal-section ul {
    margin-left: 50px;
    margin-top: 15px;
}

.legal-contact-section {
    text-align: center;
    margin-bottom: 100px;
}


/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE 
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    .legal-shifting-container {
        max-width: 100%;
    }
}

@media (min-width: 768px) {
    .legal-shifting-container {
        max-width: 750px;
    }
}

@media (min-width: 992px) {
    .legal-shifting-container {
        max-width: 970px;
    }
}

@media (min-width: 1200px) {
    .legal-shifting-container {
        max-width: 1170px;
    }
}