.contact-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    align-items: center;
    padding-top: 100px;
}

.contact-shifting-container {
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
    align-items: center;
    flex-direction: column;
}

.contact-shifting-container-row {
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
    align-items: center;
    flex-direction: row;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// INFORMATION
//
////////////////////////////////////////////////////////////////////////////////////*/

.contact-title-wrapper {
    padding-top: 50px;
    padding-bottom: 0px;
    width: 100%;
    justify-content: left;
}

.contact-title-wrapper h1 {
    font-size: 2.5rem;
    margin-bottom: 30px;
}

.contact-location-container {
    display: flex;
    padding-top: 30px;
    width: 100%;
}

.contact-location-text-wrapper {
    width: 100%;
    margin-right: 50px;
}

.contact-location-text-wrapper h2 {
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.contact-location-text-wrapper p {
    border-bottom: 1px dashed #ddd;
    font-size: 1.1em;
    padding: 20px 0;
}

.contact-location-map {
    margin-left: auto;
}

.contact-inquiry-message {
    margin: 50px 0;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// FORM
//
////////////////////////////////////////////////////////////////////////////////////*/

.contact-form-container {
    display: flex;
    width: 100%;
    justify-content: center;
    background: #f4f4f4;
}

.contact-form-description-title {
    margin-bottom: 25px;
}

.contact-form-description-content {
    line-height: 1.5em;
    margin-bottom: 50px;
}

.contact-form-description-row {
    margin-top: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid #ddd;
}

.contact-form-description-row h3 {
    font-size: 1.1em;
}

.contact-form-description-row p {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
}

.contact-form-description-row-bottom {
    margin-top: 20px;
    padding-bottom: 20px;
}

.contact-form-description-row-bottom h3 {
    font-size: 1.1em;
}

.contact-form-description-row-bottom p {
    margin-top: 10px;
    font-size: 0.9em;
    color: #555;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Form */
    .contact-form-container {
        padding: 50px 0;
    }
    .contact-form-description {
        width: 100%;
        margin-left: 0;
    }

    /* Misc */
    .contact-shifting-container {
        width: 100%;
    }
    .contact-shifting-container-row {
        width: 100%;
        flex-direction: column-reverse;
    }
}

@media (min-width: 768px) {
    /* Form */
    .contact-form-container {
        padding: 50px 0;
    }
    .contact-form-description {
        width: 100%;
        margin-left: 0;
    }

    /* Misc */
    .contact-shifting-container {
        width: 750px;
    }
    .contact-shifting-container-row {
        width: 750px;
        flex-direction: column-reverse;
    }
}

@media (min-width: 992px) {
    /* Form */
    .contact-form-container {
        padding: 100px 0;
    }
    .contact-form-description {
        width: 500px;
        margin-left: 30px;
    }
    /* Misc */
    .contact-shifting-container {
        width: 970px;
    }
    .contact-shifting-container-row {
        width: 970px;
        flex-direction: row;
    }
}

@media (min-width: 1200px) {
    /* Form */
    .contact-form-container {
        padding: 100px 0;
    }
    .contact-form-description {
        width: 500px;
    }
    /* Misc */
    .contact-shifting-container {
        width: 1170px;
    }
    .contact-shifting-container-row {
        width: 1170px;
        flex-direction: row;
    }
}



@media screen and (max-width: 960px) {
    .contact-location-container {
        flex-direction: column;
    }

    .contact-location-map {
        margin: 0;
        margin-top: 50px;
        width: 100%;
        height: 300px;
    }
}