.Footy {
    background: #224291;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    padding: 0 15px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// LOGO 
//
////////////////////////////////////////////////////////////////////////////////////*/

.foot-logo-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
}

.foot-company-logo {
    text-align: center;
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.foot-company-logo-name {
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    color: #fff;
    font-size: 0.9rem;
}

.foot-company-logo-title {
    font-size: 1.3rem;
}

.foot-partner {
    max-height: 20px;
    margin-top: 5px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// THE REST
//
////////////////////////////////////////////////////////////////////////////////////*/

.copyright {
    color: #fff;
    font-size: 0.8rem;
    margin-bottom: 20px;
    text-align: center;
}

.foot-legal-group {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 30px;
    width: 370px;
}

.foot-legal-col1,
.foot-legal-col2 {
    width: 100%;
}

.foot-link1 {
    text-decoration: none;
    color: #fff;
    margin-right: -30px;
}

.foot-link2 {
    text-decoration: none;
    color: #fff;
    margin-left: 15px;
}

.foot-link1:hover,
.foot-link2:hover {
    text-decoration: underline; 
}


.fa-linkedin {
    color: #fff;
    line-height: 50px;
    margin: 10px 0;
    font-size: 1.4rem;
    text-decoration: none;
    transition: font-size 0.2s ease-in-out;
}

.fa-linkedin:hover {
    font-size: 1.8rem;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE 
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width:768px) {
    .foot-legal-group {
        width: 300px;
    }
    .foot-link1 {
        margin-right: -32px;
    }
    .foot-link2 {
        margin-left: 8px;
    }
}