.form-container {
  display: flex;
  box-sizing: border-box;
  width: auto;
  align-items: center;
  justify-content: center;
  text-align: center;
  background: #fff;
  padding: 70px 50px;
  padding-top: 75px;
  box-shadow: 10px 5px 20px #ddd;
}

.form {
  min-width: 250px;
}

.form-title {
  margin-bottom: 50px;
}

.form-inputs {
  margin-bottom: 50px;
  position: relative;
  font-weight: bold;
}

.form-input {
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 1px solid #aaa;
  font-size: 15px;
  border-radius: 3px;
}

.form-input-number {
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 1px solid #aaa;
  font-size: 15px;
  border-radius: 3px;
}

.form-input-number::-webkit-inner-spin-button,
.form-input-number::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.form-input-number[type=number] {
  -moz-appearance: textfield;
}

.form-input-message {
  width: 100%;
  padding: 10px;
  outline: 0;
  border: 1px solid #aaa;
  font-size: 15px;
  resize: none;
  height: 10em;
  border-radius: 3px;
}

.form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form-row .form-inputs {
  flex-basis: 48%;
}

.form-label {
  position: absolute;
  left: 0;
  top: 0;
  padding: 10px;
  transition: 0.2s;
  font-size: 0.9em;
  cursor: text;
}

.form-label-message {
  position: absolute;
  left: 0;
  top: -35px;
  padding: 10px;
  font-size: 0.9em;
}

.form-input:focus ~ .form-label,
.form-input:valid ~ .form-label {
  top: -35px;
}

.form-input-number:focus ~ .form-label,
.form-input-number:valid ~ .form-label {
  top: -35px;
}

.form-inputs p {
  color: red;
  margin-top: 10px;
}

.form-input-btn {
  padding: 20px 0;
  outline: none;
  border: 1px solid #2a4eaa;
  background: #2a4eaa;
  color: #fff;
  width: 100%;
  cursor: pointer;
  font-weight: bold;
  font-size: 16px;
  border-radius: 3px;
}