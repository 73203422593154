* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Montserrat', sans-serif;
}

.App {
  background: #fff;
  height: 100%;
  justify-content: center;
}


/* Need this to keep the footer sticky to the bottom */
.content-wrap {
  position: relative;
  min-height: 100vh;
}

.resize-animation-stopper * {
  animation: none !important;
  transition: none !important;
}

.p-inline {
  display: inline;
}