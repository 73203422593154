.nav-container {
    background: #fff;
    margin: 0 auto;
    box-shadow: 0 -5px 10px #555;
    z-index: 3;
    position: fixed;
    width: 100%;
}

.nav-header {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    padding: 0 15px;
    margin: 0 auto;
    height: 100px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// LOGO 
//
////////////////////////////////////////////////////////////////////////////////////*/

.nav-left-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.nav-logo-container {
    background: #fff;
    text-align: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
}

.nav-logo-name {
    text-decoration: none;
    margin-left: auto;
    margin-right: auto;
    color: #224291;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// MENU 
//
////////////////////////////////////////////////////////////////////////////////////*/

.menu-icon {
    display: none;
}

.fa-times {
    visibility: visible;
    color: #2a4eaa;
    font-size: 2.4rem;
}

.fa-bars {
    color: #2a4eaa;
}

.nav-menu {
    display: grid;
    grid-template-columns: repeat(6, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    align-items: center;
    justify-content: right;
}

.nav-menu-links {
    width: 100%;
}

.nav-links {
    color: #333;
    text-decoration: none;
    padding: 0.5rem 1rem;
    font-weight: 600;
    text-align: left;
    position: relative;
}

.nav-links:hover {
    color: #2a4eaa;
    transition: all 0.2s ease-in-out;
}

.nav-links::after {
    content: "";
    background: #2a4eaa;
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    width: 0;
    transition: all 0.1s ease-in-out;
    
}

.nav-links:hover::after {
    width: 100%;
}

.nav-links-active {
    color: #2a4eaa;
}

.nav-links-active::after {
    content: "";
    background: #2a4eaa;
    position: absolute;
    height: 3px;
    left: 0;
    bottom: 0;
    width: 100%;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BUTTON 
//
////////////////////////////////////////////////////////////////////////////////////*/

.nav-button {
    background: #2a4eaa;
    color: #fff;
    padding: 10px 25px;
    margin: 0;
    border-radius: 3px;
    font-size: 1rem;
    cursor: pointer;
    border: 1px solid #fff;
    transition: background 0.2s ease-in-out,
                color 0.2s ease-in-out,
                border 0.2s ease-in-out;
}

.nav-button:hover {
    color: #224291;
    background: #fff;
    border: 1px solid #224291;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE 
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    .nav-header {
        max-width: 100%;
    }
    .nav-button-container {
        max-width: 100%;
    }

    /* Logo */
    .nav-left-group {
        flex-direction: column;
    }
    .navbar-logo {
        font-size: 1rem;
    }
    .nav-logo-name {
        font-size: 0.7rem;
    }
    .nav-partner {
        max-height: 15px;
        margin: 0;
        margin-top: 10px;
        object-fit: scale-down;
    }

}

@media (min-width: 768px) {
    .nav-header {
        max-width: 750px;
    }
    .nav-button-container {
        width: 750px;
    }
    .navbar-logo {
        font-size: 1rem;
    }
    .nav-partner {
        max-height: 15px;
        margin-left: 5px;
    }
}

@media (min-width: 992px) {
    .nav-header {
        max-width: 970px;
        padding: 0;
    }
    .nav-button-container {
        width: 970px;
    }
    .nav-partner {
        max-height: 18px;
        margin-left: 5px;
    }
    .navbar-logo {
        font-size: 1rem;
    }
    .nav-logo-name {
        font-size: 0.7rem;
    }
}

@media (min-width: 1200px) {
    .nav-header {
        max-width: 1170px;
    }
    .nav-button-container {
        width: 1170px;
    }
    .nav-partner {
        max-height: 20px;
        margin-left: 15px;
    }
    .navbar-logo {
        font-size: 1.3rem;
    }
    .nav-logo-name {
        font-size: 0.9rem;
    }
}

@media screen and (max-width: 992px) {
    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        position: absolute;
        top: 100px;
        left: 0;
        opacity: 0;
        visibility: hidden;
        z-index: 1;
        background: #fff;
        transition: opacity 0.2s;
    }

    .nav-menu.active {
        opacity: 1;
        visibility: visible;
        border-top: 1px solid #aaa;
        border-bottom: 1px solid #aaa;
    }

    .nav-links {
        padding: 25px;
        width: 100%;
        display: table;
    }

    .nav-links:hover {
        background-color: #c7d7ff;
        border-radius: 0;
    }

    .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 70%);
        font-size: 2rem;
        cursor: pointer;
    }

    .nav-button {
        margin-top: 10px;
        margin-bottom: 30px;
        width: 200px;
    }

}