.left-container {
    max-width: 400px;
    float: left;
    display: inline;
    margin: 0;
    padding: 0;
    border-right: 1px solid #ddd;
}

.table-links {
    text-decoration: none;
}

.table-header-links {
    text-decoration: none;
    color: #000;
}

.table-list {
    margin-bottom: 20px;
    padding-left: 65px;
    padding-right: 50px;
}

.no-bullet-list {
    list-style-type: none;
    padding-top: 30px;
}

.table-title {
    margin-left: 50px;
    margin-top: 50px;
}

.table-active {
    background: cadetblue;
}

@media screen and (max-width: 960px) {
    .left-container {
        display: none;
    }
}