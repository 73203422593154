.google-maps-wrapper {
    height: 100%;
    width: 500px;
}

.google-maps-element-height {
    height: 100%;
}

@media screen and (max-width: 960px) {
    .google-maps-wrapper {
        width: 100%;
    }
}