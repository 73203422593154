.tables-container-main {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    align-items: left;
    padding-top: 100px;
}

.tables-wrapper {
    display: inline;
    width: 100%;
    background: #fff;
    margin-top: 50px;
    margin-left: 60px;
}

.tables-collapse {
    border-collapse: collapse;
    width: 76%;
    margin-top: 20px;
}

.tables-head-left {
    text-align: left;
    color: #444;
    font-size: 20px;
    background: #ddd;
    border: 1px solid #aaa;
    border-right: 1px dashed #aaa;
    padding: 15px;
}

.tables-head-right {
    text-align: left;
    color: #444;
    font-size: 20px;
    background: #ddd;
    border: 1px solid #aaa;
    border-left: 0;
    padding: 15px;
}

.tables-company {
    border-left: 1px dashed #ddd;
    border-bottom: 1px solid #ddd;
    padding-right: 50px;
    padding-left: 15px;
}

.tables-logo {
    max-height: 50px;
    margin: 10px;
    margin-right: 100px;
}

.tables-logo-border {
    border-bottom: 1px solid #ddd;
    width: 0%;
}

.tables-spacer {
    padding: 20px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media screen and (max-width: 960px) {
    .tables-wrapper {
        display: inline;
        width: 100%;
        background: #fff;
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .tables-collapse {
        width: 100%;
    }

    .tables-logo {
        max-width: 100px;
        margin-right: 10px;
    }
}