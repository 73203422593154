.clients-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    justify-content: center;
    align-items: center;
    padding-top: 100px;
}

.clients-shifting-container {
    display: flex;
    padding: 0 15px;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BANNER
//
////////////////////////////////////////////////////////////////////////////////////*/

.clients-banner-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    text-align: center;
    align-items: center;
}

.clients-banner {
    max-height: 45vh;
}

.clients-banner-text {
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 15px;
}

.clients-banner-half-span {
    width: 50%;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// TABLE HEADER
//
////////////////////////////////////////////////////////////////////////////////////*/

.clients-header-table {
    table-layout: fixed;
    width: 100%;
    margin-top: 50px;
}

.clients-header-container-mobile {
    display: none;  
}

.clients-header {
    vertical-align: center;
    padding-top: 30px;
}

.clients-header-category {
    border-top: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    padding-top: 5px;
}

.clients-header-category:hover {
    text-decoration: underline;
}

.clients-no-decor {
    text-decoration: none;
    color: #000;
}

.clients-header-cell {
    text-align: center;
    height: 150px;
    padding: 20px;
    
}

.clients-header-cell-border {
    text-align: center;
    height: 100px;
    border-left: 1px dashed #ddd;
    border-right: 1px dashed #ddd;
}

.clients-header-img {
    max-height: 120px;
    max-width: 95%;
}

.clients-header-client-cell {
    text-align: center;
    border-bottom: 1px dashed #ddd;
    padding: 20px;
    
}

.clients-header-client-cell-border {
    text-align: center;
    border-bottom: 1px dashed #ddd;
    border-left: 1px dashed #ddd;
    border-right: 1px dashed #ddd;
}

.clients-header-client {
    padding: 10px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// TABLE BODY
//
////////////////////////////////////////////////////////////////////////////////////*/

.clients-table {
    margin-top: 50px;
    table-layout: fixed;
    width: 100%;
    border-spacing: 0px;
}

.clients-category {
    font-size: 0.95em;
    min-height: 30px;
    margin-top: 30px;
}

.clients-category-link {
    text-decoration: none;
    color: #000;
}

.clients-category-link:hover {
    text-decoration: underline
}

.clients-table-body {
    display: table-row-group;
}

.clients-table-body-mobile {
    display: none;
}

.clients-table-cell {
    background: #fff;
    vertical-align: top;
    padding: 5px;
    padding-left: 30px;
}

.clients-list {
    margin-left: 40px;
}

.clients-bullet {
    list-style: none;
    font-size: 0.95em;
    line-height: 25px;
    margin-bottom: 10px;
}

.clients-bullet::before {
    content: "\00BB";
    margin-right: 10px;
    margin-left: -17px;

}

.clients-client {
    text-decoration: none;
}

.clients-client:hover {
    text-decoration: underline;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// MISCELLANEOUS
//
////////////////////////////////////////////////////////////////////////////////////*/

.clients-spacer {
    margin: 75px 0;
    border: none;
    height: 1px;
    background-color: #ddd;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Banner */
    .clients-banner {
        transform: translate(-700px);
    }
    .clients-banner-text h1 {
        font-size: 2.3rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .clients-banner-text p {
        font-size: 0.9rem;
    }
    .clients-banner-text {
        max-width: 100%;
        text-align: center;
    }
    .clients-banner-half-span {
        width: 100%;
    }
        
    /* Table */
    .clients-table-cell {
        padding-left: 0;
    }
    
    /* Misc */
    .clients-shifting-container {
        max-width: 100%;
    }
    .clients-spacer {
        min-width: 90%;
    }
}

@media (min-width: 768px) {
    /* Banner */
    .clients-banner {
        transform: translate(-400px);
    }
    .clients-banner-text h1 {
        font-size: 2.5rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .clients-banner-text p {
        font-size: 1rem;
    }
    .clients-banner-text {
        max-width: 750px;
        text-align: center;
    }
    .clients-banner-half-span {
        width: 100%;
    }
    
    /* Table */
    .clients-table-cell {
        padding-left: 0;
    }
    
    /* Misc */
    .clients-shifting-container {
        max-width: 750px;
    }
    .clients-spacer {
        min-width: 750px;
    }

}

@media (min-width: 992px) {
    /* Banner */
    .clients-banner {
        transform: translate(0);
    }
    .clients-banner-text h1 {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }
    .clients-banner-text p {
        font-size: 1rem;
    }
    .clients-banner-text {
        max-width: 970px;
        text-align: left;
    }
    .clients-banner-half-span {
        width: 75%;
    }
    
    /* Table */
    .clients-table-cell {
        padding-left: 30px;
    }
    
    /* Misc */
    .clients-shifting-container {
        max-width: 970px;
    }
    .clients-spacer {
        min-width: 970px;
    }

}

@media (min-width: 1200px) {
    /* Banner */
    .clients-banner-text h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .clients-banner-text p {
        font-size: 1.1rem;
    }
    .clients-banner-text {
        max-width: 1170px;
        text-align: left;
    }

    /* Table */
    .clients-table-cell {
        padding-left: 30px;
    }

    /* Misc */
    .clients-shifting-container {
        max-width: 1230px;
    }
    .clients-spacer {
        min-width: 1170px;
    }
}


@media screen and (max-width: 960px) { 
    .clients-category-link {
        font-size: 1em;
    }

    .clients-title {
        margin-left: 20px;
    }

    .clients-summary {
        margin-left: 20px;
        margin-right: 20px;
        margin-bottom: 20px;
    }
    
    .clients-table {
        margin-bottom: 30px;
        table-layout: auto;
        width: 100%;
    }

    .clients-header-table {
        padding: 0px;
    }

    .clients-header-container {
        display: none;
    }

    .clients-header-container-mobile {
        display: table-row-group;
        margin: 0;
        padding: 0;
    }

    .clients-header {
        vertical-align: top;
        padding-top: 20px;
    }

    .clients-header-category {
        padding-top: 0px;
        font-size: 1.2em;
    }

    .clients-header-img {
        max-height: 100px;
        max-width: 80%;
    }

    .clients-category {
        min-height: 40px;
    }
    
    .clients-table-body {
        display: none;
    }
    
    .clients-table-body-mobile {
        display: table-row-group;
    }

    .clients-bullet {
        font-size: 0.8em;
    }
    
}