* {
    margin: 0;
}

body {
    margin: 0;

    --suncan-blue: #2a4eaa;
    --suncan-light-blue: #c7d7ff;
    --suncan-dark-blue: #224291;
    --suncan-navy: #0f1d3e;

    --suncan-gray: #f4f4f4;
    --suncan-text-gray: #555;
}
  

  