.marquee-container {
    width: 100%;
    height: 100px;
    margin: 20px;
    overflow: hidden;
    display: flex;
    position: relative;
}

.marquee-logos-wrapper-10 {
    width: 200%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    animation: marquee 40s linear infinite;
    backface-visibility: hidden;
    perspective: 1000;
    transform: rotateZ(360deg);
}

.marquee-logos-wrapper-8 {
    width: 200%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    animation: marquee 32s linear infinite;
    backface-visibility: hidden;
    perspective: 1000;
    transform: rotateZ(360deg);
    visibility: hidden;
}

.marquee-logos-wrapper-5 {
    width: 200%;
    height: 100%;
    display: flex;
    position: absolute;
    left: 0;
    animation: marquee 20s linear infinite;
    backface-visibility: hidden;
    perspective: 1000;
    transform: rotateZ(360deg);
    visibility: hidden;
}

.marquee-logos-set {
    display: flex;
    width: 100%;
    height: 50px;
    justify-content: space-around;
    align-items: center;
}

.marquee-img {
    max-height: 50px;
}

.marquee-logo-top {
    position: absolute;
    animation: crossfade 20s linear infinite;
    max-height: inherit;
}

.marquee-logo-bottom {
    position: absolute;
    animation: crossfade2 20s linear infinite;
    max-height: inherit;
}

@keyframes marquee {
    0% {
        left: 0;
    }
    100% {
        left: -100%;
    }
}

@keyframes crossfade {
    0% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    90% {
        opacity: 1;
    }
}

@keyframes crossfade2 {
    0% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    90% {
        opacity: 0;
    }
}

@media screen and (max-width: 1250px) {
    .marquee-logos-wrapper-10 {
        visibility: hidden;
    }
    
    .marquee-logos-wrapper-8 {
        visibility: visible;
    }
    
    .marquee-logos-wrapper-5 {
        visibility: hidden;
    }

    .marquee-img {
        max-height: 40px;
    }
}

@media screen and (max-width: 750px) {
    .marquee-logos-wrapper-10 {
        visibility: hidden;
    }
    
    .marquee-logos-wrapper-8 {
        visibility: hidden;
    }
    
    .marquee-logos-wrapper-5 {
        visibility: visible;
    }
    .marquee-img {
        max-height: 30px;
    }
}