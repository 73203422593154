.home-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    align-items: center;
    justify-content: space-around;
    padding-top: 100px;
}

.home-shifting-container {
    display: flex;
    padding: 0 15px;
    justify-content: space-around;
    align-items: center;
}

.home-shifting-container-reverse {
    display: flex;
    padding: 0 15px;
    justify-content: space-around;
    align-items: center;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BANNER
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-banner-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    text-align: center;
    align-items: center;
}

.home-banner {
    background-size: cover;
    filter: blur(1.1px);
    min-height: 75vh;
}

.home-banner-text {
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    margin: auto;
}

.home-banner-text h1 {
    margin-bottom: 25px;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// MARQUEE
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-marquee-title {
    font-weight: bolder;
    font-size: 1.75em;
    margin-top: 50px;
    text-align: center;
}

.home-marquee-button {
    cursor: pointer;
    color: #fff;
    background: #2a4eaa;
    border: none;
    padding: 15px 25px;
    border-radius: 3px;
    font-size: 1em;
    margin: 30px 0;
    font-weight: 550;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// PARAGRAPH
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-para-long {
    text-align: center;
    margin-top: 50px;
    padding: 0 15px;
}

.home-para-long h2 {
    margin-bottom: 20px;
    color: #333;
}

.home-para-long p {
    line-height: 1.6;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BOX CONTENT
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-box-content {
    background: #f4f4f4;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 100px 0;
}

.home-box-img {
    max-height: 350px;
    max-width: 350px;
    margin: 0;
}

.home-box-text-wrapper {
    margin-right: 100px;
    line-height: 2rem;
}

.home-box-text-wrapper2 {
    margin-left: 100px;
    line-height: 2rem;
}

.home-box-text-wrapper > h2,
.home-box-text-wrapper2 > h2 {
    margin-bottom: 50px;
    font-size: 2rem;
}

.home-box-text-wrapper > h3,
.home-box-text-wrapper2 > h3 {
    margin-bottom: 20px;
}

.home-box-text-wrapper > p,
.home-box-text-wrapper2 > p {
    color: #555;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// LOGO TRIO
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-img-trio {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    grid-gap: 20px;
    align-items: end;
}

.home-img-logo-container {
    max-width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    margin: 0 40px;
}

.home-img-logo-oracle {
    max-width: 100%;
    max-height: 150px;
    padding-bottom: 14px;
}

.home-img-logo-mysql {
    max-width: 100%;
    max-height: 150px;
}
.home-img-logo-sqlserver {
    max-width: 100%;
    max-height: 150px;
    padding-bottom: 2px;
}

.home-img-logo-title {
    margin: 80px 0;
    padding: 0 15px;
    font-size: 2rem;
    text-align: center;
}

.home-img-logo-text {
    color: #555;
    margin-top: 80px;
    padding: 0 15px;
    text-align: center;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// MISCELLANEOUS
//
////////////////////////////////////////////////////////////////////////////////////*/

.home-spacer {
    margin: 75px 0;
    border: none;
    height: 1px;
    background-color: #ddd;
}

.fa-user,
.fa-database {
    font-size: 2.5em;
    color: #2a4eaa;
    margin-bottom: 20px;
}

.fa-handshake,
.fa-cloud {
    font-size: 2.5em;
    color: #2a4eaa;
    margin-left: 30px;
}

.home-border-bottom {
    margin: 5px;
    border: none;
    height: 1px;
    background-color: #ddd;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE 
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Banner */
    .home-banner {
        transform: translate(-450px);
    }
    .home-banner-text {
        padding: 0 15px;
    }
    .home-banner-text h1 {
        font-size: 2.3rem;
        font-weight: 900;
    }
    .home-banner-text p {
        font-size: 1.2rem;
    }

    /* Logo Trio */
    .home-img-logo-oracle {
        transform: scale(0.8);
    }
    
    .home-img-logo-mysql {
        transform: scale(0.8);
    }
    .home-img-logo-sqlserver {
        transform: scale(0.8);
    }

    /* Paragraph */
    .home-para-long {
        max-width: 100%;
    }
    .home-para-long h2 {
        font-size: 2rem;
    }
    .home-para-long p {
        margin: 30px 0;
    }
    
    /* Box Content */
    .home-box-content {
        padding: 50px 0;
    }
    .home-box-img {
        margin-bottom: 50px;
    }
    .home-box-text-wrapper,
    .home-box-text-wrapper2 {
        margin: 0;
    }
    .home-box-text-wrapper > h2,
    .home-box-text-wrapper2 > h2 {
        margin: 0;
    }

    /* Misc */
    .home-shifting-container {
        max-width: 100%;
        flex-direction: column;
    }
    .home-shifting-container-reverse {
        max-width: 100%;
        flex-direction: column-reverse;
    }
    .home-spacer,
    .home-border-bottom {
        min-width: 90%;
    }
    .home-marquee-title {
        margin-top: 100px;
    }
    .fa-user,
    .fa-database {
        padding-top: 50px;
        font-size: 3em;
    }

    .fa-handshake
    .fa-cloud {
        padding-right: 30px;
        padding-top: 50px;
        margin-left: 30px;
        font-size: 2.1em;
    
    }
}

@media (min-width: 768px) {
    /* Banner */
    .home-banner {
        transform: translate(-450px);
    }
    .home-banner-text {
        padding: 0 20px;
    }
    .home-banner-text h1 {
        font-size: 2.5rem;
        font-weight: 900;
    }
    .home-banner-text p {
        font-size: 1.2rem;
    }
    
    /* Paragraph */
    .home-para-long {
        max-width: 750px;
    }
    .home-para-long h2 {
        font-size: 2rem;
    }
    .home-para-long p {
        margin: 50px 0;
    }

    /* Misc */
    .home-shifting-container,
    .home-shifting-container-reverse {
        max-width: 750px;
    }
    .home-spacer,
    .home-border-bottom {
        min-width: 750px;
    }
}

@media (min-width: 992px) {
    /* Banner */
    .home-banner {
        transform: translate(-450px);
    }
    .home-banner-text {
        padding: 0 50px;
    }
    .home-banner-text h1 {
        font-size: 3rem;
    }
    .home-banner-text p {
        font-size: 1.5rem;
    }

    /* Paragraph */
    .home-para-long {
        max-width: 970px;
    }
    .home-para-long h2 {
        font-size: 2.3rem;
    }
    .home-para-long p {
        margin: 50px 200px;
    }

    /* Misc */
    .home-shifting-container,
    .home-shifting-container-reverse {
        max-width: 970px;
    }
    .home-spacer,
    .home-border-bottom {
        min-width: 970px;
    }
}

@media (min-width: 1200px) {
    /* Banner */
    .home-banner {
        transform: translate(-400px);
    }
    .home-banner-text {
        padding: 0 100px;
    }
    .home-banner-text h1 {
        font-size: 3.5rem;
    }
    .home-banner-text p {
        font-size: 1.8rem;
    }

    /* Paragraph */
    .home-para-long {
        max-width: 1170px;
    }
    .home-para-long h2 {
        font-size: 2.3rem;
    }
    .home-para-long p {
        margin: 50px 200px;
    }

    /* Misc */
    .home-shifting-container,
    .home-shifting-container-reverse {
        max-width: 1170px;
    }

    .home-spacer,
    .home-border-bottom {
        min-width: 1170px;
    }
}