.blog-container-main {
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    background: #fff;
    align-items: center;
    padding-top: 100px;
}

.blog-shifting-container {
    display: flex;
    justify-content: space-around;
    padding: 0 15px;
    align-items: center;
    flex-direction: column;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// BANNER
//
////////////////////////////////////////////////////////////////////////////////////*/

.blog-banner-container {
    display: flex;
    overflow: hidden;
    width: 100%;
    text-align: center;
    align-items: center;
    background: #555;
    margin-bottom: 50px;
}

.blog-banner {
    height: 45vh;
}

.blog-banner-text {
    position: absolute;
    color: #fff;
    left: 0;
    right: 0;
    margin: auto;
    padding: 0 15px;
}

.blog-banner-half-span {
    width: 50%;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// COLUMN
//
////////////////////////////////////////////////////////////////////////////////////*/

.blog-column-container {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3 ,minmax(250px, 1fr));
    grid-gap: 0px;
    margin: 100px 0;
}

.blog-column {
    position: relative;
    padding: 30px;
    border-top: 1px dashed #ddd;
    border-bottom: 1px dashed #ddd;
    margin-bottom: -1px;
}

.blog-column:nth-child(3n+2) {
    border: 1px dashed #ddd;
}

.blog-icon {
    height: 100px;
    margin-bottom: 30px;
}

.blog-column h3 {
    margin-bottom: 10px;
    text-decoration: none;
}

.blog-column p {
    color: #555;
}

.blog-summary {
    margin: 30px 0;
}

.blog-link-title {
    text-decoration: none;
    color: #000;
}

.blog-post-list {
    width: 100%;
    margin-bottom: 50px;
}
/*////////////////////////////////////////////////////////////////////////////////////
//
// MENU
//
////////////////////////////////////////////////////////////////////////////////////*/

.bloglist-container {
    border-collapse: collapse;
}

.bloglist-menu-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 99;
    background: #000;
    opacity: 0.5;
}

.bloglist-edit-menu {
    width: 700px;
    height: 700px;
    position: fixed;
    z-index: 100;
    background: #fff;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: 16px;
    overflow: auto;
}

.bloglist-buttons {
    display: flex;
    justify-content: space-evenly;
}

.bloglist-buttons button {
    border: none;
    color: #fff;
    background: #2a4eaa;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
    font-weight: 700;
    margin-top: 30px;
    padding: 0 25px;
    cursor: pointer;
}

.bloglist-top-row {
    display: flex;
    align-items: center;
}

.bloglist-top-row p {
    padding-left: 20px;
    font-size: 20px;
}

.bloglist-edit-form {
    padding: 50px;
    padding-bottom: 0;
    padding-top: 0;
    display: flex;
    flex-direction: column;
    max-width: 700px;
}

.bloglist-edit-form span {
    margin-top: 20px;
    font-weight: 700;
    color: #555;
}

.bloglist-edit-form input {
    font-size: 15px;
    padding: 3px;
}

.bloglist-edit-form textarea {
    font-size: 15px;
    padding: 3px;
}

.bloglist-x-symbol {
    width: 30px;
    height: 30px;
    padding: 15px;
    cursor: pointer;
}

.bloglist-row {
    display: flex;
    justify-content: space-between;
    margin-top: -1px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}

.bloglist-row:hover {
    background: #f4f4f4;
    cursor: pointer;
    transition: background 200ms ease-in-out;
}

.bloglist-left {
    display: flex;
}

.bloglist-icon-container {
    display: flex;
    align-items: center;
    justify-content: left;
    height: 50px;
    width: 50px;
}

.bloglist-icon {
    max-height: 50px;
    max-width: 50px;
}

.bloglist-text {
    display: flex;
    flex-direction: column;
}

.bloglist-text p {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 15px;
}

.bloglist-text span {
    color: #555;
    font-size: 15px;
}

.bloglist-edit {
    padding: 5px 15px;
    height: 36px;
    background: #2a4eaa;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.bloglist-submit {
    border: none;
    color: #fff;
    background: #2a4eaa;
    width: 100%;
    font-size: 16px;
    height: 50px;
    border-radius: 4px;
    font-weight: 700;
    margin-top: 30px;
    cursor: pointer;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// POST
//
////////////////////////////////////////////////////////////////////////////////////*/

.post-container {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    width: 100%;
}

.post-header {
    display: flex;
    margin-bottom: 50px;
}

.post-heading {
    display: flex;
    flex-direction: column;
    font-size: 25px;
    justify-content: center;
}

.post-heading h3 {
    margin-top: 30px;
    font-size: 22px;
    color: #555;
    font-weight: 400;
}

.post-date { 
    font-size: 15px;
    margin: 20px 0;
    font-weight: 700;
    color: #555;
}

.post-summary {
    line-height: 35px;
    margin-bottom: 30px;
}

.post-summary h3 {
    margin-bottom: 15px;
}

.post-link {
    margin-bottom: 50px;
    line-height: 35px;
}

.post-link h3 {
    margin-bottom: 15px;
}

.post-pdf-header {
    margin-top: 30px;
    height: 50px;
    background: var(--suncan-blue);
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
}

.post-pdf-header button {
    padding: 0 20px;
    margin: 0 10px;
    border: none;
    background: var(--suncan-gray);
    border-radius: 3px;
    font-size: 20px;
    cursor: pointer;
}

.post-pdf-header p {
    margin-left: 50px;
}

.post-pdf-zoom {
    display: flex;
    align-items: center;
    text-align: center;
    margin-left: 40px;
}

.post-pdf-zoom span {
    width: 50px;
}
.post-pdf-download {
    background: var(--suncan-gray);
    padding: 3px 20px;
    border-radius: 3px;
    text-decoration: none;
    color: #000;
    margin-right: 40px;
}

.post-pdf-doc {
    display: flex;
    flex-direction: column;
    align-items: left;
    background: var(--suncan-gray);
    height: 900px;
    overflow: scroll;
    border: 1px solid #ddd;
    margin-bottom: 80px;
}

.post-pdf-page {
    width: fit-content;
    display: flex;
    justify-content: center;
    padding: 10px;
    border-bottom: 1px solid #ddd;
    margin: auto;
}

/*////////////////////////////////////////////////////////////////////////////////////
//
// RESPONSIVE
//
////////////////////////////////////////////////////////////////////////////////////*/

@media (max-width: 768px) {
    /* Banner */
    .blog-banner {
        transform: translate(-500px);
    }
    .blog-banner-text {
        max-width: 100%;
        text-align: center;
    }
    .blog-banner-text h1 {
        font-size: 2.3rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .blog-banner-text p {
        font-size: 0.9rem;
    }
    /* Misc */
    .blog-shifting-container {
        max-width: 100%;
    }
    .blog-banner-half-span {
        width: 100%;
    }
    .bloglist-row {
        padding: 15px 0;
    }
    .bloglist-icon-container {
        margin-right: 10px;
    }
    .post-header {
        flex-direction: column;
        justify-content: flex-start;
    }
    .post-heading {
        margin-left: 0;
    }

    .post-img {
        visibility: hidden;
        height: 0;
    }
}

@media (min-width: 768px) {
    /* Banner */
    .blog-banner {
        transform: translate(-250px);
    }
    .blog-banner-text {
        max-width: 750px;
        text-align: center;
    }
    .blog-banner-text h1 {
        font-size: 2.5rem;
        font-weight: 900;
        margin-bottom: 20px;
    }
    .blog-banner-text p {
        font-size: 1rem;
    }
    /* Misc */
    .blog-shifting-container {
        max-width: 750px;
    }
    .blog-banner-half-span {
        width: 100%;
    }
    .bloglist-row {
        padding: 15px 10px;
    }
    .bloglist-icon-container {
        margin-right: 20px;
    }
    .post-header {
        flex-direction: column;
        justify-content: flex-start;
    }
    .post-heading {
        margin-left: 0;
    }
    .post-img {
        visibility: hidden;
        height: 0;
    }
}

@media (min-width: 992px) {
    /* Banner */
    .blog-banner {
        transform: translate(0);
    }
    .blog-banner-text {
        max-width: 970px;
        text-align: left;
    }
    .blog-banner-text h1 {
        font-size: 2.5rem;
        margin-bottom: 30px;
    }
    .blog-banner-text p {
        font-size: 1rem;
    }
    /* Misc */
    .blog-shifting-container {
        max-width: 970px;
    }
    .blog-banner-half-span {
        width: 75%;
    }
    .bloglist-row {
        padding: 15px;
    }
    .bloglist-icon-container {
        margin-right: 30px;
    }
    .post-header {
        flex-direction: row;
    }
    .post-heading {
        margin-left: 40px;
    }
    .post-img {
        visibility: visible;
        height: 200px;
        width: 150px;
    }
}

@media (min-width: 1200px) {
    /* Banner */
    .blog-banner {
        transform: translate(0);
    }
    .blog-banner-text {
        max-width: 1170px;
        text-align: left;
    }
    .blog-banner-text h1 {
        font-size: 3rem;
        margin-bottom: 30px;
    }
    .blog-banner-text p {
        font-size: 1.1rem;
    }
    /* Misc */
    .blog-shifting-container {
        min-width: 1170px;
    }
    .bloglist-row {
        padding: 15px;
    }
    .bloglist-icon-container {
        margin-right: 30px;
    }
    .post-header {
        flex-direction: row;
    }
    .post-heading {
        margin-left: 80px;
    }
    .post-img {
        visibility: visible;
        height: 200px;
        width: 150px;
    }
}